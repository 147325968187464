<template>
    <v-container>       
        <v-data-table
                :headers="headers"
                :items="MeetingRoomList"
                :sort-desc="[true]"
                class="elevation-1"
                :custom-sort="customSort"
            >
            
                <template v-slot:[`item.dateapplied`]="{ item }">
                    <span>{{ convertDateTime(item.dateapplied) }}</span>
                </template>

                <template v-slot:[`item.start`]="{ item }">
                    <span>{{ convertDateTime(item.start) }}</span>
                </template>

                <template v-slot:[`item.end`]="{ item }">
                    <span>{{ convertDateTime(item.end) }}</span>
                </template>

                <template v-slot:[`item.assistancestatus`]="{ item }">
                    
                    <!-- <v-icon
                    v-if="item.assistancestatus == 'YES'"
                    class="success--text"
                    >
                        mdi-check-all
                    </v-icon> -->
                    <v-chip
                    v-if="item.assistancestatus == 'YES'"
                    color="blue"
                    class="text-bold white--text"
                    >
                    REQUIRED
                    </v-chip>

                    <!-- <span
                    v-if="item.assistancestatus == 'NO'"
                    class="text-bold"
                    >
                        N/A
                    </span> -->
                    <v-chip
                    v-if="item.assistancestatus == 'NO'"
                    class="text-bold"
                    >
                    N/A
                    </v-chip>

                </template>
                    
                <template v-slot:[`item.refreshmentsstatus`]="{ item }">
                    <v-chip
                    :class="controlColorFont(item.refreshmentsstatus)"
                    :color="controlBgColor(item.refreshmentsstatus)"
                    >
                    {{item.refreshmentsstatus}}
                    </v-chip>
                </template>

                <template v-slot:[`item.detail`]="{ item }">
                    <v-icon
                    color="primary"
                    dense
                    v-html="item.detail"
                    @click="openFileDoc(item)"
                    >
                    </v-icon>
                </template>

           </v-data-table>

           <!-- Popup View Details -->
            <template>
                <v-dialog
                v-model="dialogViewDataMeetingRoom"
                persistent
                scrollable
                max-height="10px"
                max-width="650px"
                >
                    <v-card>
                        <v-card-title class="title-bar-modul">
                        <span class="text-h5">Details Room Booking</span><v-icon></v-icon>
                        </v-card-title>
                        <v-card-text>
                        <v-form ref="form">
                            <v-container>

                                <v-row>
                                    <v-col>
                                        <v-simple-table id="table-details-info">
                                            <template v-slot:default>
                                            <tbody>
                                                <tr
                                                v-for="item in displayDetails"
                                                :key="item.name"
                                                >
                                                    <td :class="$vuetify.breakpoint.lgAndUp? 'font-weight-bold dontBreak' : 'font-weight-bold'">
                                                        <v-icon class="icon-text blackIcon--text" small v-if="$vuetify.breakpoint.lgAndUp">{{ item.icon }}</v-icon>
                                                        {{ item.key }}
                                                    </td>
                                                    
                                                    <td v-if="item.value != 'PENDING' && item.value != 'APPROVED' && item.value != 'REJECTED'">
                                                        <!-- <span v-html="item.value" :class="item.key == 'Refreshment'? 'text-bold primary--text' : ''"> -->
                                                            <!-- {{ item.value }} -->
                                                        <!-- </span> -->
                                                        <span v-if="item.key == 'Refreshment'" v-html="item.value" :class="item.key == 'Refreshment'? 'text-bold primary--text' : ''">
                                                        </span>
                                                        <span v-else-if="item.key == 'Technical Assistance'" v-html="item.value" :class="item.key == 'Technical Assistance'? 'text-bold primary--text' : ''">
                                                        </span>
                                                        <span v-else v-html="item.value">
                                                        </span>
                                                    </td>
                                                    
                                                    <td v-if="item.value == 'PENDING' || item.value == 'APPROVED' || item.value == 'REJECTED'">
                                                        <v-chip
                                                        dense
                                                        :class="controlColorFont(item.value)"
                                                        :color="controlBgColor(item.value)"
                                                        >
                                                        {{ item.value }}
                                                        </v-chip>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-col>
                                </v-row>

                                <v-divider></v-divider>

                                
                            </v-container>
                        </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn
                                v-if="detailData != null && detailData.refreshmentsstatus == 'PENDING'"
                                color="reject"
                                outlined
                                @click="rejectPending(detailData)"
                                >
                                    Reject
                            </v-btn>
                            <v-btn
                                v-if="detailData != null && detailData.refreshmentsstatus == 'PENDING'"
                                color="success"
                                outlined
                                @click="approvePending(detailData)"
                                >
                                    Approve
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn
                            outlined
                            color="primary"
                            @click="dialogViewDataMeetingRoom = false;"
                            >
                                Close
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </template>
    </v-container>
    
</template>

<script>
import { bus } from '@/main';
import axios from 'axios';

export default {
    data: () => ({

        MeetingRoomList: [],
        detailData: null,

        // View Data
        dialogViewDataMeetingRoom: false,
        displayDetails: [
            {key: "Event Title", attribute: "event", value: "", icon: "mdi-pin-outline"},    
            {key: "Event Description", attribute: "description", value: "", icon: "mdi-calendar-text-outline"},    
            {key: "Date Applied", attribute: "dateapplied", value: "", icon: "mdi-calendar-cursor"},    
            {key: "User Applied", attribute: "userApply", value: "", icon: "mdi-account"},    
            {key: "User Contact", attribute: "userContact", value: "", icon: "mdi-phone"},    
            {key: "Start Date", attribute: "start", value: "", icon: "mdi-calendar-start"},    
            {key: "End Date", attribute: "end", value: "", icon: "mdi-calendar-end"},    
            {key: "Meeting Room", attribute: "name", value: "", icon: "mdi-chair-rolling"},    
            {key: "Event Pax", attribute: "eventpax", value: "", icon: "mdi-account-group"},    
            {key: "Technical Assistance", attribute: "Assistanceremarks", value: "-", icon: "mdi-handshake"},    
            {key: "Refreshment", attribute: "refreshmentsremarks", value: "-", icon: "mdi-coffee"},
            {key: "Refreshment Status", attribute: "refreshmentsstatus", value: "", icon: "mdi-check-decagram-outline"},
        ],

        listRoom: [
            {id: 1, label: "MNJ 01 Meeting Room", colorCheckbox: "primary", calendarColor: "red"},
            {id: 2, label: "MNJ 02 Discussion Room", colorCheckbox: "primary", calendarColor: "blue"},
            {id: 3, label: "SPW 01 Meeting Room", colorCheckbox: "primary", calendarColor: "green"},
            {id: 4, label: "SPW 02 Meeting Room", colorCheckbox: "primary", calendarColor: "purple"},
            {id: 5, label: "SCT 01 Meeting Room", colorCheckbox: "primary", calendarColor: "orange"},
            {id: 6, label: "SIS 01 Meeting Room", colorCheckbox: "primary", calendarColor: "grey"},
        ],
        jsonEditMeetingRoom: {
            idemp: "",
            idmeetingroomtype: "",
            event: "",
            description: "",
            startdatetime: "",
            enddatetime: "",
            eventpax: "",
            assistanceremarks: "",
            refreshmentsremarks: "",
            assistanceStatus: "",
            refreshmentsstatus: ""
        },

    }),
    methods: {
        getMeetingRoomList(){

            this.MeetingRoomList = [];

            axios.get(process.env.VUE_APP_API_URL + 'meetingroom/all', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.token,
                }
            })
            .then((response) => {

                const data = response.data;

                let dataSelected = [];
                for(let i in data){
                    data[i]["detail"] = "mdi-file-document";
                    if (data[i]["assistancestatus"] == null || data[i]["assistancestatus"] == "") {
                        data[i]["assistancestatus"] = "NO";
                    }
                    if (data[i].refreshmentsstatus == "PENDING") {
                        if(data[i].bookingstatus == "BOOKED"){
                            dataSelected.push(data[i]);
                        }
                    }
                }
                this.MeetingRoomList = dataSelected;

            })
            .catch(error => {
                console.log(error.response);
            })
        },

        customSort: function(items, index, isDesc) {

            // console.log(items, index, isDesc);

            items.sort((a, b) => {
                if (index[0]=='dateapplied' || index[0]=='start' || index[0]=='end') {
                    if (!isDesc[0]) {
                        return new Date(b[index]) - new Date(a[index]);
                    } else {
                        return new Date(a[index]) - new Date(b[index]);
                    }
                }
                else {
                    if(typeof a[index] !== 'undefined'){
                        if (!isDesc[0]) {
                            return a[index].toLowerCase().localeCompare(b[index].toLowerCase());
                        }
                        else {
                            return b[index].toLowerCase().localeCompare(a[index].toLowerCase());
                        }
                    }
                }
            });
            return items;
        },

        controlColorFont(status){
            if (status == "APPROVED") {
                return "white--text text-bold" 
            }
            else if (status == "REJECTED") {
                return "white--text text-bold" 
            }
            else if (status == "PENDING") {
                return "white--text text-bold" 
            }
            else if(status == "N/A"){
                return "blackk--text text-bold" 
            }
        },
        
        controlBgColor(status){
            if (status == "APPROVED") {
                return "success" 
            }
            else if (status == "REJECTED") {
                return "reject" 
            }
            else if (status == "PENDING") {
                return "pending" 
            }
            else if(status == "N/A"){
                // return "white" 
            }
        },

         rejectPending(data){

            //  console.log("testt");

            // console.log(data);
            for(let i in this.listRoom){
                if (this.listRoom[i].label == data.name) {
                    
                    this.jsonEditMeetingRoom.idmeetingroomtype =  this.listRoom[i].id;
                }
            }


            this.jsonEditMeetingRoom.idemp = data.idemp;
            this.jsonEditMeetingRoom.event = data.event;
            this.jsonEditMeetingRoom.description = data.description;
            this.jsonEditMeetingRoom.startdatetime = data.start;
            this.jsonEditMeetingRoom.enddatetime = data.end;
            this.jsonEditMeetingRoom.eventpax = data.eventpax;
            this.jsonEditMeetingRoom.assistanceremarks = data.Assistanceremarks;
            this.jsonEditMeetingRoom.refreshmentsremarks = data.refreshmentsremarks;
            this.jsonEditMeetingRoom.assistanceStatus = data.assistanceStatus;
            this.jsonEditMeetingRoom.refreshmentsstatus = "REJECTED";

            let updateJson = JSON.stringify(this.jsonEditMeetingRoom);
            // console.log(updateJson);

            axios.put(process.env.VUE_APP_API_URL + "meetingroom/editbook",
            updateJson,
            {
                headers: {
                "Authorization": 'Bearer ' + this.$store.getters.user.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                }
            })
            .then(response => { //eslint-disable-line
                // console.log(response);
                this.dialogViewDataMeetingRoom = false;
                bus.$emit('changeIt', 'loadNotify');
                this.getMeetingRoomList()
            })
            .catch(e => {
                console.log(e.response);
            })

        },

        approvePending(data){

            for(let i in this.listRoom){
                if (this.listRoom[i].label == data.name) {
                    this.jsonEditMeetingRoom.idmeetingroomtype =  this.listRoom[i].id;
                }
            }

            this.jsonEditMeetingRoom.idemp = data.idemp;
            this.jsonEditMeetingRoom.event = data.event;
            this.jsonEditMeetingRoom.description = data.description;
            this.jsonEditMeetingRoom.startdatetime = data.start;
            this.jsonEditMeetingRoom.enddatetime = data.end;
            this.jsonEditMeetingRoom.eventpax = data.eventpax;
            this.jsonEditMeetingRoom.assistanceremarks = data.Assistanceremarks;
            this.jsonEditMeetingRoom.refreshmentsremarks = data.refreshmentsremarks;
            this.jsonEditMeetingRoom.assistanceStatus = data.assistanceStatus;
            this.jsonEditMeetingRoom.refreshmentsstatus = "APPROVED";

            let updateJson = JSON.stringify(this.jsonEditMeetingRoom);
            // console.log(updateJson);

            axios.put(process.env.VUE_APP_API_URL + "meetingroom/editbook",
            updateJson,
            {
                headers: {
                "Authorization": 'Bearer ' + this.$store.getters.user.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                }
            })
            .then(response => { //eslint-disable-line
                // console.log(response);
                this.dialogViewDataMeetingRoom = false;
                bus.$emit('changeIt', 'loadNotify');
                this.getMeetingRoomList();
            })
            .catch(e => {
                console.log(e.response);
            })
        },

        openFileDoc(data){
            // console.log("ddd", data);

            console.log(data);

            let keyData = Object.keys(data)
            for(let i in this.displayDetails){

                for(let x in keyData){
                    if (this.displayDetails[i].attribute == keyData[x]) {
                        
                        if (keyData[x] == "dateapplied" || keyData[x] == "start" || keyData[x] == "end") {
                            this.displayDetails[i].value = this.convertDateTime(data[keyData[x]]);
                        }
                        else if (keyData[x] == "description") {
                            if (data.description == "" || data.description == null) {
                                this.displayDetails[i].value = "-";
                            }
                            else{
                                this.displayDetails[i].value = data[keyData[x]];
                            }
                        }
                        else if (keyData[x] == "userContact") {
                            if (data.userContact == "" || data.userContact == null) {
                                this.displayDetails[i].value = "-";
                            }
                            else{
                                this.displayDetails[i].value = data[keyData[x]];
                            }
                        }
                        else if (keyData[x] == "assistancestatus") {
                            if (data.assistancestatus == "YES") {
                                this.displayDetails[i].value = data[keyData[x]];
                            }
                            else if (data.assistancestatus == "NO") {
                                this.displayDetails[i].value = "N/A";
                                this.displayDetails[i]
                            }
                        }
                        else if (keyData[x] == "Assistanceremarks") {
                            if (data.Assistanceremarks == ""  || data.Assistanceremarks == null) {
                                this.displayDetails[i].value = "-";
                            }
                            else{
                                this.displayDetails[i].value = data[keyData[x]];
                            }
                        }
                        else if (keyData[x] == "refreshmentsremarks") {
                            if (data.refreshmentsremarks == "") {
                                this.displayDetails[i].value = "-";
                            }
                            else{
                                this.displayDetails[i].value = data[keyData[x]];
                            }
                        }
                        else if (keyData[x] == "idmeetingroom") {
                            for(let y in this.listRoom){
                                if (data.idmeetingroom == this.listRoom[y].id) {
                                    this.displayDetails[i].value = this.listRoom[y].label;
                                }
                            }
                        }
                        else{
                            this.displayDetails[i].value = data[keyData[x]];
                        }
                    }
                }
            }
            this.detailData = data;
            this.dialogViewDataMeetingRoom = true;
        }
    },
    mounted(){
        this.getMeetingRoomList();
    },
    computed: {
        headers () {
        
            return [
                { text: 'Date Applied', value: 'dateapplied' },
                { text: 'Room Type', value: 'name' },
                { text: 'Event/Project', value: 'event' },
                { text: 'Start Date', value: 'start' },
                { text: 'End Date', value: 'end' },
                { text: 'Tech Assist', value: 'assistancestatus' },
                { text: 'Refreshment', value: 'refreshmentsstatus' },
                { text: 'Detail', value: 'detail', sortable: false },
            ]

        },
        itemsWithIndex() {
            return this.leaveList.map(
                (leaveList, index) => ({
                ...leaveList,
                index: index + 1
                }))
        }
    }

}

</script>

<style lang="scss" scoped>
@import '~scss/main';


</style>
