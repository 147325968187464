<template>
    <v-container>

        <v-card-title class="title-bar-modul">
            <v-icon>mdi-view-dashboard-outline</v-icon>
            Dashboard
        </v-card-title>

        <!-- Tab List-->
        <v-tabs
          v-model="tabs"
          show-arrows="mobile"
        >

          <!-- <v-tab
            v-for="item in itemsDashboard"
            :key="item.id"
            class="tab-icon"
          >
            <v-icon>{{ item.icon }}</v-icon>
            {{ item.name }}
          </v-tab> -->

          <v-tab
            index=0
            key="0"
            class="tab-icon"
          >
            <v-icon>mdi-view-dashboard-variant-outline</v-icon>
            Dashboard
          </v-tab>

          <v-tab
            v-if="onlyAdmin || onlyModerator || onlyCarManager || onlyRoomManager || adaKuli"
            index=1
            key="1"
            class="tab-icon"
          >
            <v-icon dense>mdi-bell</v-icon>
            Notification
          </v-tab>
          
        </v-tabs>

        <v-tabs-items
        v-model="tabs"
        >
          <v-tab-item class="container-tab-modul">
                <v-container>
                    <v-row dense>
                        <v-col cols="12" md="4">
                        <v-card
                            min-height="175"
                        >
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title class="text-h5 mb-1">
                                    TOTAL LEAVE
                                    </v-list-item-title>
                                </v-list-item-content>

                                <v-icon x-large @click="goToPage('/leave')">mdi-calendar-blank</v-icon>
                            </v-list-item>
                            <!-- <v-container> -->
                                <v-row dense>
                                    <v-col cols="12" sm="6"  >
                                        <div class="text-center">
                                            <v-progress-circular
                                            :value="valueBakiCuti > -1? annualLeaveValue : (valueBakiCuti + (Math.abs(valueBakiCuti) * 2)) * 10"
                                            :color="valueBakiCuti > -1? 'cyan accent-4' : 'red'"
                                            >
                                            <span v-if="leaveDashboard == null" v-html="'0'" class="text-bold caption"></span>
                                            <span v-else v-html="valueBakiCuti" class="text-bold caption"></span>
                                            </v-progress-circular>                          
                                        </div>
                                        <v-card-text class="pb-0 text-center">
                                            <!-- <v-icon class="mr-2" dense>mdi-airplane</v-icon> -->
                                            <span class="font-weight-bold">                                            
                                                Annual:
                                            </span> 
                                        </v-card-text>
                                        <v-card-text class="pt-0 text-center">
                                            <span v-if="leaveDashboard == null" v-html="'0 from 0'"></span>
                                            <span v-else>
                                                {{ (leaveDashboard.annualleaveused ? leaveDashboard.annualleaveused : 0) + ' from ' + (leaveDashboard.totalannualleave ? leaveDashboard.totalannualleave : 0) }}
                                                <v-tooltip max-width="450" bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon
                                                        color="primary"
                                                        dark
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        >
                                                        mdi-information
                                                        </v-icon>
                                                    </template>
                                                    <span>Total Annual Leave is the combination of Annual Leave Entitlements (based on years of service) and Carried Forward Leaves (from previous year - if any).</span>
                                                    <v-divider></v-divider>
                                                    <span v-if="new Date(Date.now()).getMonth() <= 3">
                                                        Total Carry Forward Leave: {{ leaveDashboard.countcarryforward }}
                                                    </span>
                                                </v-tooltip>
                                            </span>
                                        </v-card-text>
                                    </v-col>
                                    <v-col cols="12" sm="6" >
                                        <div class="text-center">
                                            <v-progress-circular
                                            :value="valueBakiMedical > -1? medicalLeaveValue : (valueBakiMedical + (Math.abs(valueBakiMedical) * 2)) * 10"
                                            :color="valueBakiMedical > -1? 'light-blue accent-3' : 'red'"
                                            >
                                            <span v-if="leaveDashboard == null" v-html="'0'" class="text-bold caption"></span>
                                            <span v-else v-html="valueBakiMedical" class="text-bold caption"></span>
                                            </v-progress-circular>                                            
                                        </div>
                                        <v-card-text class="pb-0 text-center">
                                            <!-- <v-icon class="mr-2" dense>mdi-medical-bag</v-icon> -->
                                            <span class="font-weight-bold">
                                                Medical:
                                            </span>
                                        </v-card-text>
                                        <v-card-text class="pt-0 text-center">
                                            <span v-if="leaveDashboard == null" v-html="'0 from 0'"></span>
                                            <span v-else v-html="(leaveDashboard.medicalleaveused ? leaveDashboard.medicalleaveused : 0) + ' from ' + (leaveDashboard.totalmedicalleave ? leaveDashboard.totalmedicalleave : 0)"></span>
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                            <!-- </v-container> -->
                        </v-card>
                        </v-col>
                        <v-col cols="12" md="8">
                            <v-card
                                min-height="175"
                            >
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title class="text-h5 mb-1">
                                        OTHER LEAVE
                                        </v-list-item-title>
                                    </v-list-item-content>

                                    <v-icon x-large @click="goToPage('/leave')">mdi-calendar-blank</v-icon>
                                </v-list-item>
                                <v-container>
                                    <v-row dense>
                                        <v-col cols="12" sm="6" v-for="item in otherLeaveVal" :key="item.leave_type">
                                            <v-card-text class="pa-0">
                                                <v-icon class="mr-2" dense>{{ item.icon }}</v-icon>
                                                <span class="font-weight-bold text-capitalize">{{ item.leave_type.toLowerCase() }}:</span>
                                                {{ item.value }}
                                            </v-card-text>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card>
                        </v-col>
                    </v-row>

                    <v-row>
                      <v-col
                      cols="12"
                      lg="4"
                      class="pt-0 pr-0">
                        <v-card
                        :height="$vuetify.breakpoint.smAndDown ? 'auto' : '590'"
                        >
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title class="text-h5 mb-1">
                                    CAR BOOKING
                                    </v-list-item-title>
                                </v-list-item-content>

                                <v-icon x-large @click="goToPage('/carBooking')">mdi-car</v-icon>
                            </v-list-item>

                            <v-container>
                                
                                <v-card-subtitle class="pt-0 pl-2">
                                    <v-icon dense class="mr-2">mdi-car-multiple</v-icon>
                                    <span class="font-weight-bold text-capitalize">Booking Record:</span> {{totalMyCarBooking}}
                                </v-card-subtitle>

                                <v-card-subtitle class="pt-0 pl-2">
                                    <v-icon dense class="mr-2">mdi-car-side</v-icon>
                                    <span class="font-weight-bold text-capitalize">Latest Car Booking:</span>
                                </v-card-subtitle>

                                <v-timeline
                                align-top
                                dense
                                >
                                    <v-timeline-item
                                    :color="setColorStatus(myCarBookingList.status)"
                                    small
                                    >
                                    <v-row class="pt-1">
                                        <v-col>
                                            <strong>
                                                Status    
                                            </strong>
                                        <div class="text-caption">
                                            <v-chip
                                            v-if="myCarBookingList.status != ''"
                                            :color="setColorStatus(myCarBookingList.status)"
                                            :class="setColorText(myCarBookingList.status)"
                                            small
                                            >
                                                {{myCarBookingList.status}}
                                            </v-chip>
                                            <span v-if="myCarBookingList.status == ''">(No Data)</span>
                                        </div>
                                        </v-col>
                                        <v-col>
                                            <strong>
                                                Event/Project    
                                            </strong>
                                            <div
                                            v-html="myCarBookingList.eventProject? myCarBookingList.eventProject : '(No Data)'"
                                            class="text-caption"
                                            ></div>
                                        </v-col>
                                    </v-row>
                                    </v-timeline-item>

                                    <v-timeline-item
                                    :color="setFromColorCar(myCarBookingList.car)"
                                    icon="mdi-car"
                                    >
                                    <v-row class="pt-1">
                                        <v-col>
                                            <strong>Car</strong>
                                            <div v-if="myCarBookingList.car != ''" class="text-caption">
                                                

                                                <v-card class="pa-1 mb-2" color="white" width="190">
                                                    <v-progress-linear
                                                        v-if="loadingImageCar"
                                                        color="primary"
                                                        indeterminate
                                                        rounded
                                                        height="6"
                                                    ></v-progress-linear>
                                                    <v-img width="190" :src="myCarBookingList.src"></v-img>
                                                </v-card>

                                                <v-icon small :color="setFromColorCar(myCarBookingList.car)">mdi-key-variant</v-icon>
                                                {{myCarBookingList.car}}
                                            </div>
                                            <div v-if="myCarBookingList.car == ''" class="text-caption">
                                                <v-icon small :color="setFromColorCar(myCarBookingList.car)">mdi-key-variant</v-icon>
                                                (No Data)
                                            </div>
                                        </v-col>
                                    </v-row>
                                    </v-timeline-item>

                                    <v-timeline-item
                                    color="primary"
                                    small
                                    >
                                    <v-row class="pt-1">
                                        <v-col>
                                            <strong>Start Date</strong>
                                            <div
                                            v-html="myCarBookingList.starDatetime? myCarBookingList.starDatetime : '(No Data)'" 
                                            class="text-caption"
                                            ></div>
                                        </v-col>
                                    </v-row>
                                    </v-timeline-item>

                                    <v-timeline-item
                                    color="primary"
                                    small
                                    >
                                    <v-row class="pt-1">
                                        <v-col>
                                            <strong>End Date</strong>
                                            <div
                                            v-html="myCarBookingList.endDatetime? myCarBookingList.endDatetime : '(No Data)'"
                                            class="text-caption"
                                            ></div>
                                        </v-col>
                                    </v-row>
                                    </v-timeline-item>

                                </v-timeline>

                            </v-container>
                        </v-card>
                      </v-col>

                      <v-col cols="12" lg="8" class="pt-0">

                          <v-row>
                              <v-col>
                                <v-card
                                min-height="175"
                                >
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-h5 mb-1">
                                            MEETING ROOM
                                            </v-list-item-title>
                                        </v-list-item-content>

                                        <v-icon x-large @click="goToPage('/meetingRoom')">mdi-forum-outline</v-icon>
                                    </v-list-item>

                                    <v-container>
                                        
                                        <v-row dense class="pa-0">
                                            <v-col class="pb-5">
                                                <v-card-subtitle class="pl-2 pt-0">
                                                    <v-icon dense class="mr-2">mdi-sofa-single</v-icon>
                                                    <span class="font-weight-bold text-capitalize">Booking Record:</span> {{totalMyRoomBooking}}
                                                </v-card-subtitle>

                                                <v-card-subtitle class="pt-0 pl-2">
                                                    <v-icon dense class="mr-2">mdi-chair-rolling</v-icon>
                                                    <span class="font-weight-bold text-capitalize">Latest Meeting Room Booking:</span>
                                                </v-card-subtitle>

                                                <v-card class="pa-1" color="white" width="350">

                                                    <v-progress-linear
                                                        v-if="loadingImageRoom"
                                                        color="primary"
                                                        indeterminate
                                                        rounded
                                                        height="6"
                                                    ></v-progress-linear>

                                                    <v-carousel
                                                    :continuous="true"
                                                    :cycle="cycle"
                                                    :show-arrows="false"
                                                    hide-delimiters
                                                    width="auto"
                                                    height="auto"
                                                    >
                                                        <v-carousel-item
                                                            v-for="(slide, i) in pathImageMeetingRoom"
                                                            :key="i"
                                                        >
                                                            
                                                            <v-sheet
                                                            height="auto"
                                                            width="350"
                                                            tile
                                                            >
                                                            
                                                            <v-row
                                                                class="fill-height"
                                                                align="center"
                                                                justify="center"
                                                            >
                                                                <div class="text-h2">
                                                                    <v-img height="150" width="350" class="img-slider" :src="slide.src"></v-img>
                                                                </div>
                                                            </v-row>
                                                            </v-sheet>
                                                        </v-carousel-item>
                                                    </v-carousel>
                                                </v-card>
                                                <br>
                                                <small v-if="myRoomBookingList.room != ''">
                                                    <v-icon class="red--text" >mdi-map-marker-radius</v-icon>
                                                    <!-- SIS 01 Meeting Room -->
                                                    {{myRoomBookingList.room}}
                                                </small>
                                                <small v-if="myRoomBookingList.room == ''">
                                                    <v-icon class="red--text" >mdi-map-marker-radius</v-icon>
                                                    (No Data)
                                                </small>
                                            </v-col>
                                            <v-col>
                                                <v-stepper
                                                elevation="0"
                                                tile
                                                vertical
                                                class="pa-0 cust-dark"
                                                >
                                                    <v-stepper-step
                                                    step="1"
                                                    complete
                                                    complete-icon="mdi-pin"
                                                    color="brown lighten-1"
                                                    class="pt-0"
                                                    >
                                                        Event/Project
                                                        <small
                                                        v-html="myRoomBookingList.eventProject? myRoomBookingList.eventProject : '(No Data)'"
                                                        class="mt-2 text-bold"
                                                        >
                                                            <!-- MEMS 2.0 Development -->
                                                        </small>
                                                    </v-stepper-step>

                                                    <v-stepper-content step="1">
                                                    </v-stepper-content>

                                                    <v-stepper-step
                                                    step="2"
                                                    complete
                                                    :complete-icon="setIconRefresh(myRoomBookingList.refreshStatus)"
                                                    :color="setColorStatus(myRoomBookingList.refreshStatus)"
                                                    class="py-0"
                                                    >
                                                        <span>
                                                            <v-icon small>mdi-coffee</v-icon> Refreshment Status
                                                        </span>
                                                        <small v-if="myRoomBookingList.refreshStatus != ''" class="mt-2 text-bold">
                                                            <v-chip
                                                            :color="setColorStatus(myRoomBookingList.refreshStatus)"
                                                            :class="setColorText(myRoomBookingList.refreshStatus)"
                                                            small
                                                            >
                                                                <!-- APPROVED -->
                                                                {{myRoomBookingList.refreshStatus}}
                                                            </v-chip>
                                                        </small>
                                                        <small v-if="myRoomBookingList.refreshStatus == ''">
                                                            (No Data)
                                                        </small>
                                                    </v-stepper-step>

                                                    <v-stepper-content step="2">
                                                    </v-stepper-content>

                                                    <v-stepper-step
                                                    step="3"
                                                    complete
                                                    complete-icon="mdi-calendar"
                                                    class="pb-0"
                                                    >
                                                        Start Date
                                                        <small
                                                        v-html="myRoomBookingList.starDatetime? myRoomBookingList.starDatetime : '(No Data)'"
                                                        class="mt-1 text-bold"
                                                        >
                                                            <!-- 25 Feb 2022, 2:00:00 PM -->
                                                        </small>
                                                    </v-stepper-step>

                                                    <v-stepper-content step="3">
                                                    </v-stepper-content>

                                                    <v-stepper-step
                                                    step="4"
                                                    complete
                                                    complete-icon="mdi-calendar"
                                                    >
                                                        End Date
                                                        <small
                                                        v-html="myRoomBookingList.endDatetime? myRoomBookingList.endDatetime : '(No Data)'"
                                                        class="mt-1 text-bold"
                                                        >
                                                            <!-- 25 Feb 2022, 4:30:00 PM -->
                                                        </small>
                                                    </v-stepper-step>

                                                    <v-stepper-content step="4">
                                                    </v-stepper-content>
                                                </v-stepper>

                                            </v-col>
                                        </v-row>

                                    </v-container>
                                </v-card>
                            </v-col>
                          </v-row>

                          <v-row dense>
                            <v-col>
                                <v-card>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-h5 mb-1">
                                            ASSET
                                            </v-list-item-title>
                                        </v-list-item-content>

                                        <v-icon x-large @click="goToPage('/assetInventory')">mdi-laptop</v-icon>
                                    </v-list-item>

                                    <v-container class="pt-0">
                                    <v-row dense>
                                        <v-col class="py-0">
                                            <v-row class="py-0">
                                                <v-col class="py-0">
                                                    <v-card-subtitle class="pl-2 pt-2 pb-0">
                                                        <v-icon dense class="mr-2">mdi-desktop-tower-monitor</v-icon>
                                                        <span class="font-weight-bold text-capitalize">Total Asset:</span> {{totalMyAsset}}
                                                    </v-card-subtitle>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col class="pb-0">

                                                    <v-card-subtitle class="pl-2 pt-2 dontBreak">
                                                        <v-icon dense class="mr-2">mdi-laptop</v-icon>
                                                        <span class="font-weight-bold text-capitalize">Laptop:</span> {{totalLaptop}}
                                                        <br>
                                                        <v-icon class="ml-7 mr-2" small>mdi-arrow-right-bottom</v-icon>
                                                        <small v-if="totalLaptop != 0" class="text-capitalize">{{myLaptopBrands.join(", ")}}</small>
                                                        <small v-if="totalLaptop == 0" class="text-capitalize">(No Data)</small>
                                                    </v-card-subtitle>

                                                    <v-card-subtitle class="pt-0 pl-2 dontBreak">
                                                        <v-icon dense class="mr-2">mdi-desktop-tower</v-icon>
                                                        <span class="font-weight-bold text-capitalize">Desktop:</span> {{totalDesktop}}
                                                        <br>
                                                        <v-icon class="ml-7 mr-2" small>mdi-arrow-right-bottom</v-icon>
                                                        <small v-if="totalDesktop != 0" class="text-capitalize">{{myDesktopBrands.join(", ")}}</small>
                                                        <small v-if="totalDesktop == 0" class="text-capitalize">(No Data)</small>
                                                    </v-card-subtitle>

                                                </v-col>
                                                <v-col class="pb-0">

                                                    <v-card-subtitle class="pt-2 pl-2 dontBreak">
                                                        <v-icon dense class="mr-2">mdi-monitor</v-icon>
                                                        <span class="font-weight-bold text-capitalize">Monitor:</span> {{totalMonitor}}
                                                        <br>
                                                        <v-icon class="ml-7 mr-2" small>mdi-arrow-right-bottom</v-icon>
                                                        <small v-if="totalMonitor != 0" class="text-capitalize">{{myMonitorBrands.join(", ")}}</small>
                                                        <small v-if="totalMonitor == 0" class="text-capitalize">(No Data)</small>
                                                    </v-card-subtitle>

                                                    <v-card-subtitle class="pt-0 pl-2 dontBreak">
                                                        <v-icon dense class="mr-2">mdi-mouse-variant</v-icon>
                                                        <span class="font-weight-bold text-capitalize">Accessories:</span> {{totalAccessories}}
                                                        <br>
                                                        <v-icon class="ml-7 mr-2" small>mdi-arrow-right-bottom</v-icon>
                                                        <small v-if="totalAccessories != 0" class="text-capitalize">{{myAccessoriesBrands.join(", ")}}</small>
                                                        <small v-if="totalAccessories == 0" class="text-capitalize">(No Data)</small>
                                                    </v-card-subtitle>

                                                </v-col>
                                            </v-row>
                                        </v-col>

                                        <v-divider
                                        v-if="$vuetify.breakpoint.lgAndUp"
                                        class="mr-8"
                                        vertical
                                        aria-orientation="horizontal"
                                        ></v-divider>

                                        <v-col>
                                            <v-card class="pa-1" color="white" max-width="300" min-width="300">

                                                <v-progress-linear
                                                    v-if="loadingImageAsset"
                                                    color="primary"
                                                    indeterminate
                                                    rounded
                                                    height="6"
                                                ></v-progress-linear>

                                                <v-carousel
                                                interval="3000"
                                                :continuous="true"
                                                :cycle="cycle"
                                                :show-arrows="false"
                                                hide-delimiters
                                                width="80"
                                                height="150"
                                                >
                                                    <v-carousel-item
                                                        v-for="(slide, i) in pathImageAsset"
                                                        :key="i"
                                                    >
                                                        
                                                        <v-sheet
                                                        height="100"
                                                        width="300"
                                                        tile
                                                        >
                                                        
                                                        <v-row
                                                            class="fill-height"
                                                            align="center"
                                                            justify="center"
                                                        >
                                                            <div class="text-h2">
                                                            <!-- {{ slide }} Slide -->
                                                            <v-img width="300" class="img-slider" :src="slide.src"></v-img>
                                                            </div>
                                                        </v-row>
                                                        </v-sheet>
                                                    </v-carousel-item>
                                                </v-carousel>
                                            </v-card>
                                        </v-col>

                                    </v-row>
                                    </v-container>
                                </v-card>
                            </v-col>

                          </v-row>

                      </v-col>

                      

                      <v-col>
                          <v-row>
                              
                          </v-row>

                      </v-col>
                    </v-row>
                </v-container>

          </v-tab-item>
          <v-tab-item class="container-tab-modul">
            <!-- Notification -->
            <template>
              <v-card>
                <v-tabs
                  v-model="tabNotification"
                  background-color="#06466E"
                  centered
                  dark
                  icons-and-text
                  show-arrows="mobile"
                  height="90"
                >
                  <v-tabs-slider></v-tabs-slider>

                  <v-tab href="#tab-1" v-if="onlyAdmin || onlyModerator || adaKuli">
                    Leave Application
                    <v-badge
                    v-if="countNotifyLeave != null"
                    color="red"
                    :content="countNotifyLeave"
                    >
                        <v-icon>mdi-calendar-cursor</v-icon>
                    </v-badge>
                    <v-icon v-if="countNotifyLeave == null">mdi-calendar-cursor</v-icon>
                  </v-tab>

                  <v-tab href="#tab-3" v-if="onlyAdmin || onlyCarManager">
                    Car Booking
                    <v-badge
                    v-if="countNotifyCar != null"
                    color="red"
                    :content="countNotifyCar"
                    >
                        <v-icon>mdi-car</v-icon>
                    </v-badge>
                    <v-icon v-if="countNotifyCar == null">mdi-car</v-icon>
                  </v-tab>

                  <v-tab href="#tab-2" v-if="onlyAdmin || onlyRoomManager">
                    Meeting Room
                    <v-badge
                    v-if="countNotifyRoom != null"
                    color="red"
                    :content="countNotifyRoom"
                    >
                        <v-icon>mdi-forum</v-icon>
                    </v-badge>
                    <v-icon v-if="countNotifyRoom == null">mdi-forum</v-icon>
                  </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tabNotification">
                  <v-tab-item
                  :value="'tab-1'"
                  >
                    <v-card flat>
                      <leave-application>

                      </leave-application>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item
                  :value="'tab-2'"
                  >
                    <v-card flat>
                      <meeting-room-booking>

                      </meeting-room-booking>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item
                  :value="'tab-3'"
                  >
                    <v-card flat>
                      <car-booking>

                      </car-booking>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-card>
            </template>
          </v-tab-item>
        </v-tabs-items>

        

    </v-container>
    
</template>

<script>
import { bus } from '@/main';
import Leave from '@/components/dashboard/notification/Leave';
import MeetingRoom from '@/components/dashboard/notification/MeetingRoom';
import Car from '@/components/dashboard/notification/Car';
import axios from 'axios';

export default {
    components: {
        'leave-application': Leave,
        'meeting-room-booking': MeetingRoom,
        'car-booking': Car
    },

    data: () => ({
        tabs: 0,
        itemsDashboard: [
          {
            index: 0,
            name: 'Dashboard',
            icon: 'mdi-view-dashboard-variant-outline',
          },
          {
            index: 1,
            name: 'Notification',
            icon: 'mdi-bell',
          }
        ],
        dtToday : new Date(Date.now()),
        thisYear : null,

        tabNotification: null,
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',

        // Leave dashboard
        leaveDashboard: '',
        annualLeaveValue: '',
        medicalLeaveValue: '',
        otherLeaveVal: [
            {
                leave_type: 'BEREAVEMENT LEAVE',
                value: 0,
                icon: 'mdi-home-heart'
            },
            {
                leave_type: 'MARRIAGE LEAVE',
                value: 0,
                icon: 'mdi-human-male-female'
            },
            {
                leave_type: 'MATERNITY LEAVE',
                value: 0,
                icon: 'mdi-human-female-boy'
            },
            {
                leave_type: 'PATERNITY LEAVE',
                value: 0,
                icon: 'mdi-human-male-child'
            },
            {
                leave_type: 'HOSPITALIZATION LEAVE',
                value: 0,
                icon: 'mdi-hospital-building'
            },
            {
                leave_type: 'REPLACEMENT LEAVE',
                value: 0,
                icon: 'mdi-calendar'
            },
        ],


        // Car Dashboard
        loadingImageCar: false,
        totalMyCarBooking: 0,
        myCarData: [],
        myCarBookingList: {
            starDatetime: '',
            endDatetime: '',
            status: '',
            eventProject: '',
            car: '',
            src: ''
        },
        


        // Meeting Room Dashboard
        loadingImageRoom: false,
        listAllRooms: [
            {
                id: 0,
                src: require('@/assets/img/meetingRooms/none-image.jpg'),
                name: ""
            },
            {
                id: 1,
                src: require('@/assets/img/meetingRooms/MNJ1.jpg'),
                name: "MNJ 01 Meeting Room"
            },
            {
                id: 2,
                src: require('@/assets/img/meetingRooms/MNJD.jpg'),
                name: "MNJ 02 Discussion Room"
            },
            {
                id: 3,
                src: require('@/assets/img/meetingRooms/SPW1.jpg'),
                name: "SPW 01 Meeting Room"
            },
            {
                id: 4,
                src: require('@/assets/img/meetingRooms/SPW2.jpg'),
                name: "SPW 02 Meeting Room"
            },
            {
                id: 5,
                src: require('@/assets/img/meetingRooms/SPW3.jpg'),
                name: "SCT 01 Meeting Room"
            },
            {
                id: 6,
                src: require('@/assets/img/meetingRooms/SIS1.jpg'),
                name: "SIS 01 Meeting Room"
            },
        ],
        pathImageMeetingRoom: [],
        totalMyRoomBooking: 0,
        myRoomData: [],
        myRoomBookingList: {
            starDatetime: '',
            endDatetime: '',
            refreshStatus: '',
            eventProject: '',
            room: '',
        },

        // Asset Dashboard
        cycle: true,
        loadingImageAsset: false,
        assetNoGambar: [
            { src: require('@/assets/img/meetingRooms/none-image.jpg') }
        ],
        pathImageAsset: [
            // {
            //     src: require('@/assets/img/infra/laptop.jpg'),
            // },
            // {
            //     src: require('@/assets/img/infra/monitor.jpg'),
            // },
            // {
            //     src: require('@/assets/img/infra/keyboard.jpg'),
            // },
            // {
            //     src: require('@/assets/img/infra/mouse.jpg'),
            // },
        ],
        myAssetData: [],
        myLaptopData: [],
        myLaptopBrands: [],
        myDesktopData: [],
        myDesktopBrands: [],
        myMonitorData: [],
        myMonitorBrands: [],
        myAccessoriesData: [],
        myAccessoriesBrands: [],
        totalMyAsset: 0,
        totalLaptop: 0,
        totalDesktop: 0,
        totalMonitor: 0,
        totalAccessories: 0,


        // NOTIFY SECTION
        totalPendCar: null,
        pendCar: [],
        kiraNotiCar: null,

        totalPendLeave: null,
        pendLeave: [],
        kiraNotiLeave: null,

        totalPendMeetingRoom: null,
        pendMeetingRoom: [],
        kiraNotiMeetingRoom: null,

    }),

    methods: {

        getThisYear(){
            this.year = this.dtToday.getFullYear();
        },


        // NOTIFICATION PART

        focusNotify(){

            if (window.focusNotify == 1) {
                this.tabs = 1;
            }
            else{
                this.tabs = 0;
            }
        },

        getNotifyLeave(){

            let dtToday = new Date(Date.now());
            let thisYear = dtToday.getFullYear()
            // console.log(thisYear);
            this.pendLeave= [],
            this.kiraNotiLeave= null,
            this.totalPendLeave= null,
            
            axios.get(process.env.VUE_APP_API_URL + 'cuti/mykulicutis?startdt='+thisYear+'-01-01&enddt='+thisYear+'-12-31', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {
                
                let data = response.data;
                // console.log(data);

                const empId = this.$store.getters.user.idEmp;

                for(let i = 0; i < data.length; i++){
                    if(empId == data[i].supervisorid){
                        if (data[i].supervisorapproved == "PENDING" || data[i].supervisorapproved == null) {
                            this.pendLeave.push(data[i]);
                            this.kiraNotiLeave = this.pendLeave.length;
                        }
                    } 
                    else if(empId == data[i].superiorid){
                        if (data[i].superiorapproved == "PENDING" || data[i].superiorapproved == null) {
                            this.pendLeave.push(data[i]);
                            this.kiraNotiLeave = this.pendLeave.length;
                        }
                    }
                }

                this.totalPendLeave = this.kiraNotiLeave;
                
                
            })
            .catch(error => {
                console.log(error.response);
            })
        },

        getNotifyCar(){

            // let dtToday = new Date(Date.now());
            const d = new Date();
            let thisYear = d.getFullYear();
            let prevD = d.setFullYear(d.getFullYear()-1);
            let prevYear = new Date(prevD).getFullYear();
            // console.log(thisYear);
            this.pendCar= [],
            this.kiraNotiCar= null,
            this.totalPendCar= null,
            
            axios.get(process.env.VUE_APP_API_URL + 'car/allbookings?startdt='+prevYear+'-01-01T00:00:00&enddt='+thisYear+'-12-31T23:59:59&withrejected=1', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {
                
                let data = response.data;
                // console.log(data);

                for(let i in data){
                    if(data[i].status == "PENDING" || data[i].status == "pending" || data[i].status == "Pending"){

                        for(let x in this.globalListCar){

                            if (data[i].carType == this.globalListCar[x].label.toUpperCase()) {
                                if (this.globalListCar[x].idApproval == this.$store.getters.user.idEmp
                                || this.$store.getters.user.idUserAccess == 1) {
                                    this.pendCar.push(data[i]);
                                    this.kiraNotiCar = this.pendCar.length;
                                }
                            }
                        }
                        
                    }
                }

                this.totalPendCar = this.kiraNotiCar;
                
            })
            .catch(error => {
                console.log(error.response);
            })
        },

        getNotifyRoom(){
            
            this.pendMeetingRoom= [],
            this.kiraNotiMeetingRoom= null,
            this.totalPendMeetingRoom= null,
            
            axios.get(process.env.VUE_APP_API_URL + 'meetingroom/all', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.user.token,
                }
            })
            .then((response) => {
                
                let data = response.data;

                for(let i in data){
                    if(data[i].refreshmentsstatus == "PENDING" || data[i].refreshmentsstatus == "pending" || data[i].refreshmentsstatus == "Pending"){
                        if(data[i].bookingstatus == "BOOKED"){
                            this.pendMeetingRoom.push(data[i]);
                            this.kiraNotiMeetingRoom = this.pendMeetingRoom.length;
                        }
                    }
                }

                this.totalPendMeetingRoom = this.kiraNotiMeetingRoom;
                
            })
            .catch(error => {
                console.log(error.response);
            })
        },



        // MAIN DASHBOARD

        getLeaveDashboard(){
            axios.get(process.env.VUE_APP_API_URL + 'employees/staffinfo?tab=3&id='+this.$store.getters.user.idEmp, {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.token,
                }
            })
            .then((response) => {
                this.leaveDashboard = response.data.info;

                if(this.leaveDashboard != null ){
                    // this.annualLeaveValue = (parseFloat(this.leaveDashboard.annualleaveused) / parseFloat(this.leaveDashboard.totalannualleave)) * 100;
                    this.annualLeaveValue = ((parseFloat(this.leaveDashboard.totalannualleave) - parseFloat(this.leaveDashboard.annualleaveused)) / parseFloat(this.leaveDashboard.totalannualleave)) * 100;
                
                    // this.medicalLeaveValue = (parseFloat(this.leaveDashboard.medicalleaveused) / parseFloat(this.leaveDashboard.totalmedicalleave)) * 100;
                    this.medicalLeaveValue = ((parseFloat(this.leaveDashboard.totalmedicalleave) - parseFloat(this.leaveDashboard.medicalleaveused)) / parseFloat(this.leaveDashboard.totalmedicalleave)) * 100;
                } else {
                    this.annualLeaveValue = parseInt(0);
                    this.medicalLeaveValue = parseInt(0);
                }


            })
            .catch(error => {
                console.log(error.response);
            })


            axios.get(process.env.VUE_APP_API_URL + 'cuti/all?self=1', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.token,
                }
            })
            .then((response) => {

                const leaves = response.data;
                const leaveVal =  [
                    {
                        leave_type: 'BEREAVEMENT LEAVE',
                        value: 0,
                        icon: 'mdi-home-heart'
                    },
                    {
                        leave_type: 'MARRIAGE LEAVE',
                        value: 0,
                        icon: 'mdi-human-male-female'
                    },
                    {
                        leave_type: 'MATERNITY LEAVE',
                        value: 0,
                        icon: 'mdi-human-female-boy'
                    },
                    {
                        leave_type: 'PATERNITY LEAVE',
                        value: 0,
                        icon: 'mdi-human-male-child'
                    },
                    {
                        leave_type: 'HOSPITALIZATION LEAVE',
                        value: 0,
                        icon: 'mdi-hospital-building'
                    },
                    {
                        leave_type: 'REPLACEMENT LEAVE',
                        value: 0,
                        icon: 'mdi-calendar'
                    },
                ];

                for(let i = 0; i < leaves.length; i++){
                    for(let k in leaveVal){
                        if(leaveVal[k].leave_type == leaves[i].leavetype){
                            if(leaves[i].leavestatus == 'APPROVED'){
                                leaveVal[k].value = parseFloat(leaveVal[k].value) + parseFloat(leaves[i].countused);
                            }
                        }
                    }
                }

                this.otherLeaveVal = leaveVal;
            })
            .catch(error => {
                console.log(error.response);
            })


        },


        getCarDashboard(){

            const d = new Date();
            let thisYear = d.getFullYear();
            let prevD = d.setFullYear(d.getFullYear()-1);
            let prevYear = new Date(prevD).getFullYear();

            this.loadingImageCar = true;

            this.myCarData = [];

            axios.get(process.env.VUE_APP_API_URL + 'car/allbookings?startdt='+prevYear+'-01-01T00:00:00&enddt='+thisYear+'-12-31T23:59:59&withrejected=1', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.token,
                }
            })
            .then((response) => {
                let data = response.data;
                // console.log(data);

                if (data.length > 0) {
                    // Filter only my booking
                    const empId = this.$store.getters.user.idEmp;
                    for(let i in data){
                        if (data[i].idUser == empId) {
                            this.myCarData.push(data[i]);
                        }
                    }
                    // console.log(this.myCarData);

                    // Assign to json for display on html
                    if (this.myCarData.length > 0) {
                        this.myCarBookingList.starDatetime = this.convertDateTime(this.myCarData[0].start);
                        this.myCarBookingList.endDatetime = this.convertDateTime(this.myCarData[0].end);
                        this.myCarBookingList.status = this.myCarData[0].status;
                        this.myCarBookingList.eventProject = this.myCarData[0].name;
                        this.myCarBookingList.car = this.myCarData[0].carType;
                        // console.log(this.myCarBookingList);

                        for(let i in this.globalListCar){
                            if (this.myCarData[0].carType.toUpperCase() == this.globalListCar[i]['label'].toUpperCase()) {
                                this.myCarBookingList.src = this.globalListCar[i]['src'];
                            }
                        }

                        // Total history my car booking
                        this.totalMyCarBooking = this.myCarData.length;
                        this.loadingImageCar = false;
                    }
                    
                }
                else{
                    this.loadingImageCar = false;
                }

                // console.log(this.myCarBookingList);
                
            })
            .catch(error => {
                console.log(error.response);
                this.loadingImageCar = false;
            })
        },

        setFromColorCar(car){

            if (car != "") {
                let color = car.split("(")[1].split(" - ")[0].toLowerCase();
                if (color == "silver" || color == "white") {
                   return "blue-grey lighten-4"; 
                }
                else{
                    return color;
                }
            }
            else{
                return "grey";
            }
            
        },

        setColorStatus(status){
            if (status == "APPROVED") {
                return "success" 
            }
            else if (status == "REJECTED") {
                return "reject" 
            }
            else if (status == "PENDING") {
                return "pending" 
            }
            else if(status == "N/A"){
                return "purple" 
            }
            else{
                return "grey"
            }
        },

        setColorText(status){
            if (status == "APPROVED") {
                return "white--text text-bold" 
            }
            else if (status == "REJECTED") {
                return "white--text text-bold" 
            }
            else if (status == "PENDING") {
                return "white--text text-bold" 
            }
            else if(status == "N/A"){
                return "white--text text-bold" 
            }
            else{
                return "white--text text-bold"
            }
        },


        getRoomDashboard(){

            this.loadingImageRoom = true;

            this.myRoomData = [];

            axios.get(process.env.VUE_APP_API_URL + 'meetingroom/all', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.token,
                }
            })
            .then((response) => {
                let data = response.data;
                // console.log(data);

                if (data.length > 0) {
                    // Filter only my booking
                    const empId = this.$store.getters.user.idEmp;
                    for(let i in data){
                        if (data[i].idemp == empId) {
                            this.myRoomData.push(data[i]);
                        }
                    }
                    // console.log(this.myRoomData);

                    // Assign to json for display on html
                    if (this.myRoomData.length > 0) {
                        for(let i in this.listAllRooms){
                            if (this.listAllRooms[i].id == this.myRoomData[0].idmeetingroom) {
                                this.pathImageMeetingRoom.push(this.listAllRooms[i]);
                            }
                        }
                        this.myRoomBookingList.starDatetime = this.convertDateTime(this.myRoomData[0].start);
                        this.myRoomBookingList.endDatetime = this.convertDateTime(this.myRoomData[0].end);
                        this.myRoomBookingList.refreshStatus = this.myRoomData[0].refreshmentsstatus;
                        this.myRoomBookingList.eventProject = this.myRoomData[0].event;
                        this.myRoomBookingList.room = this.myRoomData[0].name;
                        // console.log(this.myRoomBookingList);

                        // Total history my room booking
                        this.totalMyRoomBooking = this.myRoomData.length;

                        this.loadingImageRoom = false;
                    }
                    else{
                        this.pathImageMeetingRoom.push(this.listAllRooms[0]);
                        this.loadingImageRoom = false;
                    }
                    
                }
                else if(data.length == 0){
                    this.pathImageMeetingRoom.push(this.listAllRooms[0]);
                    this.loadingImageRoom = false;
                }

                
            })
            .catch(error => {
                console.log(error.response);
                this.loadingImageRoom = false;
                this.pathImageMeetingRoom.push(this.listAllRooms[0]);
            })
        },

        setIconRefresh(status){
            if (status == "APPROVED") {
                return "mdi-food" 
            }
            else if (status == "REJECTED") {
                return "mdi-food-off-outline" 
            }
            else if (status == "PENDING") {
                return "mdi-dots-horizontal" 
            }
            else if(status == "N/A"){
                return "mdi-circle-small" 
            }
            else{
                return "mdi-radiobox-blank" 
            }
        },


        getAssetDashboard(){

            this.loadingImageAsset = true;

            axios.get(process.env.VUE_APP_API_URL + 'asset/all', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.token,
                }
            })
            .then((response) => {
                let data = response.data;
                // console.log(data);

                if (data.length > 0) {
                    // Filter only my booking
                    const empId = this.$store.getters.user.idEmp;
                    for(let i in data){
                        if (data[i].idemp == empId) {
                            this.myAssetData.push(data[i]);
                        }
                    }
                    // console.log(this.myAssetData);

                    // Kumpulkan semua my asset picture
                    let collectionGambar = [];
                    for(let x in this.myAssetData){
                        if (this.myAssetData[x].picture != null && this.myAssetData[x].picture != '') {
                           collectionGambar.push({src: this.myAssetData[x].picture}); 
                        }
                    }
                    if (collectionGambar.length > 0) {
                        this.loadingImageAsset = false;
                        this.pathImageAsset = collectionGambar;
                    }
                    else{
                        this.loadingImageAsset = false;
                        this.pathImageAsset.push(this.assetNoGambar[0]);
                    }
                    
                    // console.log(this.pathImageAsset);

                    let key = [
                        'brand',
                        'assetname',
                    ],
                    dataBrand = this.myAssetData.map(o => Object.assign(...key.map(k => ({ [k]: o[k] }))));

                    // Assign to json for display on html
                    let brLaptop = [], brDesktop = [], brMonitor = [], brAccessories = [];
                    if (dataBrand.length > 0) {

                        for(let i in dataBrand){
                            if (dataBrand[i].assetname == "Laptop") {
                                this.myLaptopData.push(dataBrand[i]);
                                brLaptop.push(dataBrand[i].brand);
                            }
                            else if (dataBrand[i].assetname == "Desktop") {
                                this.myDesktopData.push(dataBrand[i]);
                                brDesktop.push(dataBrand[i].brand);
                            }
                            else if (dataBrand[i].assetname == "Monitor") {
                                this.myMonitorData.push(dataBrand[i]);
                                brMonitor.push(dataBrand[i].brand);
                            }
                            else if (dataBrand[i].assetname == "Accessories") {
                                this.myAccessoriesData.push(dataBrand[i]);
                                brAccessories.push(dataBrand[i].brand);
                            }
                        }

                        // list brand (filter if has same brand's names)
                        
                        for(let i in brLaptop ){
                            let keyData = Object.keys(brLaptop[i])
                            let isHasNotEqual = true
                            for(let j in this.myLaptopBrands ){
                                for(let x in keyData){
                                    if (this.myLaptopBrands[j][keyData[x]]===brLaptop[i][keyData[x]]){
                                        isHasNotEqual=false
                                    }
                                }
                            }
                            if (isHasNotEqual){
                                this.myLaptopBrands.push(brLaptop[i])
                            }
                        }

                        for(let i in brDesktop ){
                            let keyData = Object.keys(brDesktop[i])
                            let isHasNotEqual = true
                            for(let j in this.myDesktopBrands ){
                                for(let x in keyData){
                                    if (this.myDesktopBrands[j][keyData[x]]===brDesktop[i][keyData[x]]){
                                        isHasNotEqual=false
                                    }
                                }
                            }
                            if (isHasNotEqual){
                                this.myDesktopBrands.push(brDesktop[i])
                            }
                        }

                        for(let i in brMonitor ){
                            let keyData = Object.keys(brMonitor[i])
                            let isHasNotEqual = true
                            for(let j in this.myMonitorBrands ){
                                for(let x in keyData){
                                    if (this.myMonitorBrands[j][keyData[x]]===brMonitor[i][keyData[x]]){
                                        isHasNotEqual=false
                                    }
                                }
                            }
                            if (isHasNotEqual){
                                this.myMonitorBrands.push(brMonitor[i])
                            }
                        }

                        for(let i in brAccessories ){
                            let keyData = Object.keys(brAccessories[i])
                            let isHasNotEqual = true
                            for(let j in this.myAccessoriesBrands ){
                                for(let x in keyData){
                                    if (this.myAccessoriesBrands[j][keyData[x]]===brAccessories[i][keyData[x]]){
                                        isHasNotEqual=false
                                    }
                                }
                            }
                            if (isHasNotEqual){
                                this.myAccessoriesBrands.push(brAccessories[i])
                            }
                        }

                        // Total all my asset
                        this.totalMyAsset = this.myAssetData.length;
                        this.totalLaptop = this.myLaptopData.length;
                        this.totalDesktop = this.myDesktopData.length;
                        this.totalMonitor = this.myMonitorData.length;
                        this.totalAccessories = this.myAccessoriesData.length;
                    }
                    
                }
                
            })
            .catch(error => {
                this.loadingImageAsset = false;
                console.log(error.response);
            })

        }

    },
    mounted () {
        
        this.focusNotify();
        this.getNotifyLeave();
        this.getNotifyCar();
        this.getNotifyRoom();

        this.getThisYear();
        this.getLeaveDashboard();
        this.getCarDashboard();
        this.getRoomDashboard();
        this.getAssetDashboard();
    },
    computed: {

        valueBakiCuti(){
            let value = (this.leaveDashboard.totalannualleave ? this.leaveDashboard.totalannualleave : 0) - (this.leaveDashboard.annualleaveused ? this.leaveDashboard.annualleaveused : 0)
            return value;
        },

        valueBakiMedical(){
            let value = (this.leaveDashboard.totalmedicalleave ? this.leaveDashboard.totalmedicalleave : 0) - (this.leaveDashboard.medicalleaveused ? this.leaveDashboard.medicalleaveused : 0)
            return value;
        },

        countNotifyLeave(){
            let count = null;
            let leave = null;

            if (this.totalPendLeave != null) {
                leave = this.totalPendLeave;
            }
            else{
                leave = 0;
            }

            if (leave > 0) {
                count = leave;
                return count;
            }
            else{
                return null;
            }
        },
        countNotifyCar(){
            let count = null;
            let car = null;

            if (this.totalPendCar != null) {
                car = this.totalPendCar;
            }
            else{
                car = 0;
            }

            if (car > 0) {
                count = car;
                return count;
            }
            else{
                return null;
            } 
        },
        countNotifyRoom(){
            let count = null;
            let room = null;

            if (this.totalPendMeetingRoom != null) {
                room = this.totalPendMeetingRoom;
            }
            else{
                room = 0;
            }

            if (room > 0) {
                count = room;
                return count;
            }
            else{
                return null;
            } 
        },
    },
    created (){
        bus.$on('changeIt', (data) => {
            
            if (data == "loadNotify") {
                this.getNotifyLeave();
                this.getNotifyCar();
                this.getNotifyRoom();
            }
            else if (data == "goToNotiList") {
                this.tabs = 1;
            }

        })
    }
    
}

</script>

<style lang="scss">
@import '~scss/main';

.img-slider{
    background-size: cover !important;
    background-repeat: no-repeat !important;
}

</style>
