<template>
    <v-container>       
        <template>
            <v-data-table
                :headers="headers"
                :items="leaveList"
                :sort-by="['dateapplied']"
                :sort-asc="[true]"
                :custom-sort="customSort"
                class="elevation-1"
            >
                <template v-slot:[`item.dateapplied`]="{ item }">
                    <span>{{ convertDateTime(item.dateapplied) }}</span>
                </template>

                <template v-slot:[`item.startdate`]="{ item }">
                    <span>{{ convertDateTime(item.startdate) }}</span>
                </template>

                <template v-slot:[`item.enddatetime`]="{ item }">
                    <span>{{ convertDateTime(item.enddatetime) }}</span>
                </template>

                <template v-slot:[`item.approval`]="{ item }">
                    <v-chip
                        :color="getColor(item.approval)"
                        dark
                    >
                        <!-- {{ item.approval == null ? 'PENDING' : item.approval }} -->
                        {{ item.approval }}
                    </v-chip>
                </template>

                <template v-slot:[`item.detail`]="props">
                    <template>
                        <v-icon dense class="mr-2 primary--text" @click="updateLeaveItem(props.item)">mdi-file-document</v-icon>
                    </template>
                </template>

                <template v-slot:top>
                    <v-dialog
                    v-model="detailsDialog"
                    max-width="750px"
                    scrollable
                    >
                        <v-card>
                            <v-card-title class="justify-center title-bar-modul">
                                <h4 class="text-capitalize">{{ detailsLeave.leavetype.toLowerCase() }} Form</h4>
                            </v-card-title>

                            <v-card-text>
                                <v-simple-table>
                                    <tbody>
                                        <tr>
                                            <td class="font-weight-bold">Applicant Name:</td>

                                            <td>
                                                {{ detailsLeave.name }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold">Leave Type:</td>

                                            <td>
                                                {{ detailsLeave.leavetype }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold">Start Date:</td>

                                            <td>
                                                {{ convertDateTime(detailsLeave.startdate) }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold">End Date:</td>

                                            <td>
                                                {{ convertDateTime(detailsLeave.enddatetime) }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold">No of Days Applied:</td>

                                            <td>
                                                {{ detailsLeave.countused }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold">Address During Leave:</td>

                                            <td>
                                                {{ detailsLeave.address }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold">Reason:</td>

                                            <td>
                                                {{ detailsLeave.reason }}
                                            </td>
                                        </tr>
                                        <tr v-if="detailsLeave.leavetype == 'MEDICAL LEAVE'">
                                            <td class="font-weight-bold">Medical Certificate:</td>
                                                
                                            <td>
                                                <v-btn
                                                    class="ma-0"
                                                    color="primary"
                                                    dark
                                                    small
                                                    @click="viewMC(detailsLeave.fileupload)"
                                                >
                                                    <v-icon dark small>
                                                    mdi-file-document-outline
                                                    </v-icon>
                                                </v-btn>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="font-weight-bold">Leave Status:</td>

                                            <td>
                                                <span 
                                                v-if="detailsLeave.leavestatus == 'PENDING'" 
                                                class="orange--text">
                                                    <strong>PENDING</strong>
                                                </span>
                                                <span 
                                                v-else-if="detailsLeave.leavestatus == 'APPROVED'" 
                                                class="green--text">
                                                    <strong>{{ detailsLeave.leavestatus }}</strong>
                                                </span>
                                                <span 
                                                v-else-if="detailsLeave.leavestatus == 'REJECTED'" 
                                                class="red--text">
                                                    <strong>{{ detailsLeave.leavestatus }}</strong>
                                                </span>
                                                <span v-else class="pink--text text--darken-2">
                                                    {{ detailsLeave.leavestatus }}
                                                </span>                                            
                                            </td>
                                        </tr>
                                    </tbody>
                                </v-simple-table>

                                <v-divider></v-divider>

                                <template>
                                    <v-container class="pa-2">
                                        <v-row>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                        >
                                            <v-card min-height="245px" class="cardLeave">
                                            <v-card-title class="font-weight-bold justify-center pa-2">
                                                <h5>SUPERVISOR APPROVAL</h5>
                                            </v-card-title>

                                            <v-divider></v-divider>

                                            <v-simple-table>
                                                <tbody>
                                                    <tr>
                                                        <td class="font-weight-bold">Submission Date:</td>
                                                        <td>
                                                            {{ convertDateTime(detailsLeave.dateapplied) }}
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td class="font-weight-bold">Supervisor Name:</td>
                                                        <td>
                                                            {{ detailsLeave.supervisorname }}
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td class="font-weight-bold">Supervisor Approval: 
                                                            <v-tooltip max-width="450" bottom v-if="detailsLeave.leavestatus == 'PENDING'">
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-icon
                                                                    color="primary"
                                                                    dark
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                    >
                                                                    mdi-information
                                                                    </v-icon>
                                                                </template>
                                                                <span>If you act as Supervisor and Superior for this employee, you only need to approve at the Superior column only.</span>
                                                            </v-tooltip>
                                                        </td>
                                                        <td v-if="$store.getters.user.idEmp == detailsLeave.supervisorid && detailsLeave.supervisorapproved == null && detailsLeave.supervisorid != detailsLeave.superiorid">
                                                            <v-select
                                                            :items="approvalStatus"
                                                            item-text="text"
                                                            item-value="value"
                                                            v-model="leaveStatus"
                                                            label=""
                                                            outlined
                                                            dense
                                                            hide-details
                                                            ></v-select>
                                                        </td>
                                                        <td v-else>
                                                            <span 
                                                            v-if="detailsLeave.supervisorapproved == null" 
                                                            class="orange--text">
                                                                <strong>PENDING</strong>
                                                            </span>
                                                            <span 
                                                            v-else-if="detailsLeave.supervisorapproved == 'APPROVED'" 
                                                            class="green--text">
                                                                <strong>{{ detailsLeave.supervisorapproved }}</strong>
                                                            </span>
                                                            <span 
                                                            v-else-if="detailsLeave.supervisorapproved == 'REJECTED'" 
                                                            class="red--text">
                                                                <strong>{{ detailsLeave.supervisorapproved }}</strong>
                                                            </span>
                                                            <span v-else class="pink--text text--darken-2">
                                                                {{ detailsLeave.supervisorapproved }}
                                                            </span>
                                                            
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </v-simple-table>
                                            </v-card>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                        >
                                            <v-card min-height="245px" class="cardLeave">
                                            <v-card-title class="font-weight-bold justify-center pa-2">
                                                <h5>SUPERIOR APPROVAL</h5>
                                            </v-card-title>

                                            <v-divider></v-divider>

                                            <v-simple-table>
                                                <tbody>
                                                    <tr>
                                                        <td class="font-weight-bold">Submission Date:</td>
                                                        <td>
                                                            {{ convertDateTime(detailsLeave.dateapplied) }}
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td class="font-weight-bold">Superior Name:</td>
                                                        <td>
                                                            {{ detailsLeave.superiorname }}
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td class="font-weight-bold">Superior Approval:</td>
                                                        <td v-if="$store.getters.user.idEmp == detailsLeave.superiorid && detailsLeave.superiorapproved == null">
                                                            <v-select
                                                            :items="approvalStatus"
                                                            item-text="text"
                                                            item-value="value"
                                                            v-model="leaveStatus"
                                                            label=""
                                                            outlined
                                                            dense
                                                            hide-details
                                                            ></v-select>
                                                        </td>
                                                        <td v-else>
                                                            <span 
                                                            v-if="detailsLeave.superiorapproved == null" 
                                                            class="orange--text">
                                                                <strong>PENDING</strong>
                                                            </span>
                                                            <span 
                                                            v-else-if="detailsLeave.superiorapproved == 'APPROVED'" 
                                                            class="green--text">
                                                                <strong>{{ detailsLeave.superiorapproved }}</strong>
                                                            </span>
                                                            <span 
                                                            v-else-if="detailsLeave.superiorapproved == 'REJECTED'" 
                                                            class="red--text">
                                                                <strong>{{ detailsLeave.superiorapproved }}</strong>
                                                            </span>
                                                            <span v-else class="pink--text text--darken-2">
                                                                {{ detailsLeave.superiorapproved }}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </v-simple-table>
                                            </v-card>
                                        </v-col>
                                        </v-row>
                                    </v-container>
                                </template>

                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="red"
                                    outlined
                                    @click="close"
                                >
                                    Close
                                </v-btn>
                                <v-btn
                                    v-if="$store.getters.user.idEmp == detailsLeave.superiorid && detailsLeave.superiorapproved == null"
                                    color="blue darken-1"
                                    outlined
                                    @click="submitFormLeave()"
                                >
                                    Submit
                                </v-btn>
                                <v-btn
                                    v-else-if="$store.getters.user.idEmp == detailsLeave.supervisorid && detailsLeave.supervisorapproved == null"
                                    color="blue darken-1"
                                    outlined
                                    @click="submitFormLeave()"
                                >
                                    Submit
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </template>
            </v-data-table>
        </template>
        <!-- Snackbar for error and success notification -->
        <template>
            <div class="text-center">
                <v-snackbar
                v-model="snackbar"
                :timeout="timeout"
                :color="messagecolor"
                >
                {{ messagetext }}

                <template v-slot:action="{ attrs }">
                    <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                    >
                    Close
                    </v-btn>
                </template>
                </v-snackbar>
            </div>
        </template>
    </v-container>
    
    

</template>

<script>
import { bus } from '@/main';
import axios from 'axios';

export default {
    data: () => ({
        
        leaveList : [],
        detailsLeave: {
            idemp: '',
            name: '',
            dateapplied: '',
            leavetype: '',
            startdate: '',
            enddatetime: '',
            supervisorapproved: '',
            superiorapproved: '',
            supervisorid: '',
            superiorid: '',
            supervisorname: '',
            superiorname: '',
            countused: '',
            leavestatus: '',
            totalannualleave: '',
            annualleaveadjustment: '',
            annualleaveused: '',
            totalmedicalleave: '',
            medicalleaveused: '',
            address: '',
            reason: '',
            fileupload: ''
        },
        detailsDialog: false,

        approvalStatus: [
            {
                text:'APPROVE',
                value:'APPROVED',
            },
            {
                text: 'REJECT',
                value: 'REJECTED'
            }],
        leaveStatus: '',

        snackbar: false,
        timeout: 3000,
        messagecolor: '',
        messagetext: '',


        
    }),

    computed: {
        headers () {
        
            return [
            //   {
            //     text: 'Dessert (100g serving)',
            //     align: 'start',
            //     sortable: false,
            //     value: 'name',
            //   },
                { text: 'Leave Type', value: 'leavetype' },
                { text: 'Date Submitted', value: 'dateapplied' },
                { text: 'Applicant Name', value: 'name' },
                { text: 'Start Date', value: 'startdate' },
                { text: 'End Date', value: 'enddatetime' },
                { text: 'Approval', value: 'approval' },
                { text: 'Detail', value: 'detail', sortable: false, align:'center'},
            ]

        },
        itemsWithIndex() {
            return this.leaveList.map(
                (leaveList, index) => ({
                ...leaveList,
                index: index + 1
                }))
        }
    },

    methods: {

        customSort: function(items, index, isDesc) {

            // console.log(items, index, isDesc);
            
            items.sort((a, b) => {
                if (index[0]=='dateapplied' || index[0]=='startdate' || index[0]=='enddatetime') {
                    if (!isDesc[0]) {
                        return new Date(b[index]) - new Date(a[index]);
                    } else {
                        return new Date(a[index]) - new Date(b[index]);
                    }
                }
                else {
                    if(typeof a[index] !== 'undefined'){
                        if (!isDesc[0]) {
                            return a[index].toLowerCase().localeCompare(b[index].toLowerCase());
                        }
                        else {
                            return b[index].toLowerCase().localeCompare(a[index].toLowerCase());
                        }
                    }
                }
            });
            return items;
        },

        getColor (leavestatus) {
            if (leavestatus == 'REJECTED') return 'red'
            else if (leavestatus == 'APPROVED') return 'green'
            else if (leavestatus == 'CANCELLED') return 'pink darken-2'
            else return 'orange'
        },

        getLeaveList(){
            axios.get(process.env.VUE_APP_API_URL + 'cuti/mykulicutis?startdt=2022-01-01&enddt=2022-12-31', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.token,
                }
            })
            .then((response) => {

                const leave = response.data;
                
                const empId = this.$store.getters.user.idEmp;

                // console.log(response.data);

                for(let i = 0; i < leave.length; i++){
                    if(empId == leave[i].supervisorid){
                        leave[i].approval = leave[i].supervisorapproved == null ? 'PENDING' : leave[i].supervisorapproved;
                    } 
                    else if(empId == leave[i].superiorid){
                        leave[i].approval = leave[i].superiorapproved == null ? 'PENDING' : leave[i].superiorapproved;
                    }
                }

                
                // for(let i in leave){
                //     leave[i]["approval"] = leave[i]["leavestatus"];
                // }
                
                this.leaveList = leave;
                
                // console.log(this.leaveList);

            })
            .catch(error => {
                console.log(error);
            })
        },
        updateLeaveItem(item) { 
            this.editedIndex = this.leaveList.indexOf(item);
            this.detailsLeave = Object.assign({}, item);
            this.detailsDialog = true;
        },
        close () {
            this.detailsDialog = false
        },

        viewMC(link){
            if(link != null && link != ''){
                window.open(
                    link,
                    '_blank'
                );
            } else {
                this.messagetext = 'The medical certificate is not available.'
                this.messagecolor = 'error'
                this.snackbar = true
            }
        },

        submitFormLeave() {        
                    
            axios.get(process.env.VUE_APP_API_URL + 'cuti/approvekulicutis?startdt='+ this.detailsLeave.startdate + '&enddt=' + this.detailsLeave.enddatetime + '&idemp=' + this.detailsLeave.idemp + '&status=' + this.leaveStatus, {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.token,
                }
            })
            .then((response) => {//eslint-disable-line
                // console.log(response.data);
                this.messagetext = 'This leave successfully updated!'
                this.messagecolor = 'success'
                this.snackbar = true;
                this.detailsDialog = false;
                this.getLeaveList();
                bus.$emit('changeIt', 'loadNotify');

            })
            .catch(error => {
                this.messagetext = error.response.data.message;
                this.snackbar = true;
                this.messagecolor = 'error';
            })
        },
    },
    mounted() {
        this.getLeaveList();
    }

}

</script>

<style lang="scss" scoped>
@import '~scss/main';

.overflow-visible {
    white-space: normal !important;
}

.details-title {
    max-width: 200px;
}

</style>
