<template>
    <v-container>       
       <template>
           <v-data-table
                :headers="headers"
                :items="carBookingList"
                :sort-desc="[true]"
                :custom-sort="customSort"
                class="elevation-1"
            >
            
                <template v-slot:[`item.dateapplied`]="{ item }">
                    <span>{{ convertDateTime(item.dateapplied) }}</span>
                </template>

                <template v-slot:[`item.start`]="{ item }">
                    <span>{{ convertDateTime(item.start) }}</span>
                </template>

                <template v-slot:[`item.end`]="{ item }">
                    <span>{{ convertDateTime(item.end) }}</span>
                </template>

                <template v-slot:[`item.status`]="{ item }">
                    <v-chip
                    :color="controlBgColor(item.status)"
                    :class="controlColorFont(item.status)"
                    >
                    {{item.status}}
                    </v-chip>
                </template>

                <template v-slot:[`item.detail`]="{ item }">
                    <v-icon
                    color="primary"
                    dense
                    v-html="item.detail"
                    @click="openFileDoc(item)"
                    >
                    </v-icon>
                </template>

           </v-data-table>
       </template>

       <!-- Popup View Details -->
        <template>
            <v-dialog
            v-model="dialogViewDataCarBook"
            persistent
            scrollable
            max-height="10px"
            max-width="650px"
            >
            <v-card>
                <v-card-title class="title-bar-modul">
                <span class="text-h5">Details Car Booking</span><v-icon></v-icon>
                </v-card-title>
                <v-card-text>
                <v-form ref="form">
                    <v-container>

                        <v-row>
                            <v-col>
                                <v-simple-table class="table-details-info">
                                    <template v-slot:default>
                                    <tbody>
                                        <tr
                                        v-for="item in displayDetails"
                                        :key="item.name"
                                        >
                                            <td :class="$vuetify.breakpoint.lgAndUp? 'font-weight-bold dontBreak' : 'font-weight-bold'">
                                                <v-icon class="icon-text blackIcon--text" small v-if="$vuetify.breakpoint.lgAndUp">{{ item.icon }}</v-icon>
                                                {{ item.key }}
                                            </td>

                                            <td>

                                                <span v-if="item.value != 'PENDING' && item.value != 'APPROVED' && item.value != 'REJECTED' && item.attribute != 'screenshot'">
                                                    {{ item.value }}
                                                </span>

                                                <v-chip
                                                v-if="item.value == 'APPROVED' && item.attribute != 'screenshot'"
                                                color="success"
                                                class="white--text"
                                                >
                                                    {{ item.value }}
                                                </v-chip>

                                                <v-chip
                                                v-if="item.value == 'REJECTED' && item.attribute != 'screenshot'"
                                                color="reject"
                                                class="white--text"
                                                >
                                                    {{ item.value }}
                                                </v-chip>
                                                
                                                <v-chip
                                                v-if="item.value == 'PENDING' && item.attribute != 'screenshot'"
                                                color="pending"
                                                class="white--text"
                                                >
                                                    {{ item.value }}
                                                </v-chip>
                                                
                                                <span v-if="item.value != 'PENDING' && item.attribute == 'screenshot'">
                                                    <a v-if="item.value != null" :href="item.value" target="_blank">
                                                        <v-icon small class="ml-3 primary--text">mdi-message-image-outline</v-icon>
                                                        <span class="ml-2 primary--text" style="font-size:0.9em;">See Data Image</span>
                                                    </a>
                                                    <a v-if="item.value == null" style="cursor:default;">
                                                        <v-icon small class="ml-3 blackk--text" style="cursor:default;">mdi-image-off</v-icon>
                                                        <span class="ml-2 blackk--text" style="font-size:0.9em;cursor:default;">No Data Image</span>
                                                    </a> 
                                                </span>
                                                    
                                            
                                            </td>
                                        </tr>
                                    </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>

                        <v-divider></v-divider>

                        
                    </v-container>
                </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        v-if="detailData != null && detailData.status == 'PENDING'"
                        color="reject"
                        outlined
                        @click="rejectPending(detailData)"
                        >
                            Reject
                    </v-btn>
                    <v-btn
                        v-if="detailData != null && detailData.status == 'PENDING'"
                        color="success"
                        outlined
                        @click="approvePending(detailData)"
                        >
                            Approve
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                    color= primary
                    outlined
                    @click="dialogViewDataCarBook = false;"
                    >
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        </template>
     
    </v-container>
    

</template>

<script>
import { bus } from '@/main'
import axios from 'axios';

export default {
    data: () => ({

        carBookingList: [],
        detailsCar: {
            idcartype: '',
            startdate: '',
            enddate: '',
            status: '',
            screenshot: null,
            name: '',
            detailevent: '',
            mileagecar: ''
        },

        detailData: null, 

        // Approve OR Reject
        // listCar: [
        //     { id: 1, idApproval: 'FT159', label: "Perodua Bezza (Red - SSA2469A)", colorCheckbox: "primary", calendarColor: "ssa2469a", plate: "SSA 2469 A", company: "SPATIALWORKS SDN BHD", events: "dailyCar"},
        //     { id: 2, idApproval: 'FT159', label: "Perodua Bezza (Blue - SSA2369A)", colorCheckbox: "primary", calendarColor: "ssa2369a", plate: "SSA 2369 A", company: "SPATIALWORKS SDN BHD", events: "dailyCar"},
        //     { id: 3, idApproval: 'FT159', label: "Perodua Axia (Blue - VFP8523)", colorCheckbox: "primary", calendarColor: "vfp8523", plate: "VFP 8523", company: "SPATIALWORKS SDN BHD", events: "projectCar"},
        //     { id: 4, idApproval: 'FT138', label: "Ford Ranger (Blue - SS6955W)", colorCheckbox: "primary", calendarColor: "ss6955w", plate: "SS 6955 W", company: "SCIENTIFIC ENVIRONMENT SDN BHD", events: "projectCar"},
        //     { id: 5, idApproval: 'FT138', label: "Isuzu DMax (Grey - BME8395)", colorCheckbox: "primary", calendarColor: "bme8395", plate: "BME 8395", company: "SCIENOTECH SDN BHD", events: "projectCar"},
        //     // { id: 6, idApproval: 'CT2010', label: "Perodua Axia (Silver - VP2514)", colorCheckbox: "primary", calendarColor: "vp2514", plate: "VP 2514", company: "SCIENOTECH SDN BHD", events: "projectCar"},
        //     { id: 7, idApproval: 'CT2010', label: "Perodua Axia (White - DDA413)", colorCheckbox: "primary", calendarColor: "dda413", plate: "DDA 413", company: "SCIENOTECH SDN BHD", events: "projectCar"},
        //     { id: 8, idApproval: 'CT2010', label: "Perodua Axia (White - PMS8914)", colorCheckbox: "primary", calendarColor: "pms8914", plate: "PMS 8914", company: "SCIENOTECH SDN BHD", events: "projectCar"},
        // ],

        // View Data
        dialogViewDataCarBook: false,
        displayDetails: [
            {key: "Event Car", attribute: "eventCar", value: "", icon: "mdi-car-select"},    
            {key: "Date Applied", attribute: "dateapplied", value: "", icon: "mdi-calendar-cursor"},    
            {key: "User Applied", attribute: "userApply", value: "", icon: "mdi-account"},    
            {key: "User Contact", attribute: "userContact", value: "", icon: "mdi-phone"},    
            {key: "Start Date Time", attribute: "start", value: "", icon: "mdi-calendar-start"},    
            {key: "End Date Time", attribute: "end", value: "", icon: "mdi-calendar-end"},    
            {key: "Car", attribute: "carType", value: "", icon: "mdi-car-multiple"},    
            {key: "Event/Project Name", attribute: "name", value: "", icon: "mdi-pin-outline"},    
            {key: "Event/Project Details", attribute: "detailEvent", value: "", icon: "mdi-calendar-text-outline"},    
            {key: "Latest Mileage", attribute: "mileageCar", value: "", icon: "mdi-speedometer"},    
            {key: "Mileage Data Image", attribute: "screenshot", value: "", icon: "mdi-speedometer"},    
            {key: "Status", attribute: "status", value: "", icon: "mdi-check-decagram-outline"},    
        ],
        jsonEditBookCar: {
            idcartype: "",
            startdate: "",
            enddate: "",
            status: "",
            screenshot: "",
            name: "",
            detailevent: "",
            mileagecar: "",
        },

    }),
    methods: {

        getCarBookingList(){

            // console.log(this.$store.getters.user.idUserAccess);

            const d = new Date();
            let thisYear = d.getFullYear();
            let prevD = d.setFullYear(d.getFullYear()-1);
            let prevYear = new Date(prevD).getFullYear();

            axios.get(process.env.VUE_APP_API_URL + 'car/allbookings?startdt='+prevYear+'-01-01T00:00:00&enddt='+thisYear+'-12-31T23:59:59&withrejected=1', {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.getters.token,
                }
            })
            .then((response) => {

                const data = response.data;

                // console.log(data);

                let dataSelected = [];
                for(let i in data){
                    data[i]["detail"] = "mdi-file-document";

                    if (data[i].status == "PENDING") {

                        for(let x in this.globalListCar){

                            if (data[i].carType == this.globalListCar[x].label.toUpperCase()) {
                                if (this.globalListCar[x].idApproval == this.$store.getters.user.idEmp
                                || this.$store.getters.user.idUserAccess == 1) {
                                    
                                    dataSelected.push(data[i]);
                                }
                            }

                        }
                        
                    }

                }

                this.carBookingList = dataSelected;

            })
            .catch(error => {
                console.log(error.response);
            })
        },

        customSort: function(items, index, isDesc) {

            // console.log(items, index, isDesc);

            items.sort((a, b) => {
                if (index[0]=='dateapplied' || index[0]=='start' || index[0]=='end') {
                    if (!isDesc[0]) {
                        return new Date(b[index]) - new Date(a[index]);
                    } else {
                        return new Date(a[index]) - new Date(b[index]);
                    }
                }
                else {
                    if(typeof a[index] !== 'undefined'){
                        if (!isDesc[0]) {
                            return a[index].toLowerCase().localeCompare(b[index].toLowerCase());
                        }
                        else {
                            return b[index].toLowerCase().localeCompare(a[index].toLowerCase());
                        }
                    }
                }
            });
            return items;
        },

        controlColorFont(status){
            if (status == "APPROVED") {
                return "white--text text-bold" 
            }
            else if (status == "REJECTED") {
                return "white--text text-bold" 
            }
            else if (status == "PENDING") {
                return "white--text text-bold" 
            }
            else if(status == "N/A"){
                return "blackk--text text-bold" 
            }
        },
        
        controlBgColor(status){
            if (status == "APPROVED") {
                return "success" 
            }
            else if (status == "REJECTED") {
                return "reject" 
            }
            else if (status == "PENDING") {
                return "pending" 
            }
            else if(status == "N/A"){
                // return "white" 
            }
        },

        rejectPending(data){
            // console.log(data);
            // console.log(this.globalListCar);

            for(let i in this.globalListCar){
                if (this.globalListCar[i].label.toUpperCase() == data.carType) {
                    this.jsonEditBookCar.idcartype =  this.globalListCar[i].id;
                    // console.log(this.globalListCar[i].id);
                }
            }
            this.jsonEditBookCar.startdate = data.start;
            this.jsonEditBookCar.enddate = data.end;
            this.jsonEditBookCar.status = "REJECTED";
            this.jsonEditBookCar.screenshot = data.screenshot;
            this.jsonEditBookCar.name = data.name;
            this.jsonEditBookCar.detailevent = data.detailEvent;
            this.jsonEditBookCar.mileagecar = data.mileageCar;

            let updateJson = JSON.stringify(this.jsonEditBookCar);
            // console.log(updateJson);

            axios.put(process.env.VUE_APP_API_URL + "car/editbookcar",
            updateJson,
            {
                headers: {
                "Authorization": 'Bearer ' + this.$store.getters.user.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                }
            })
            .then(response => { //eslint-disable-line
                // console.log(response);
                this.dialogViewDataCarBook = false;
                this.getCarBookingList()
                bus.$emit('changeIt', 'loadNotify');
            })
            .catch(e => {
                console.log(e.response);
            })

        },

        approvePending(data){
            // console.log(data);
            for(let i in this.globalListCar){
                if (this.globalListCar[i].label.toUpperCase() == data.carType) {
                    this.jsonEditBookCar.idcartype =  this.globalListCar[i].id;
                    // console.log(this.globalListCar[i].id);
                }
            }
            this.jsonEditBookCar.startdate = data.start;
            this.jsonEditBookCar.enddate = data.end;
            this.jsonEditBookCar.status = "APPROVED";
            this.jsonEditBookCar.screenshot = data.screenshot;
            this.jsonEditBookCar.name = data.name;
            this.jsonEditBookCar.detailevent = data.detailEvent;
            this.jsonEditBookCar.mileagecar = data.mileageCar;

            let updateJson = JSON.stringify(this.jsonEditBookCar);
            // console.log(updateJson);

            axios.put(process.env.VUE_APP_API_URL + "car/editbookcar",
            updateJson,
            {
                headers: {
                "Authorization": 'Bearer ' + this.$store.getters.user.token,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                }
            })
            .then(response => { //eslint-disable-line
                // console.log(response);
                this.dialogViewDataCarBook = false;
                this.getCarBookingList()
                bus.$emit('changeIt', 'loadNotify');
            })
            .catch(e => {
                console.log(e.response);
            })
        },

        openFileDoc(data){
            // console.log(data);
            let keyData = Object.keys(data)
            for(let i in this.displayDetails){
                for(let x in keyData){
                    if (this.displayDetails[i].attribute == keyData[x]) {
                        
                        if (keyData[x] == "dateapplied" || keyData[x] == "start" || keyData[x] == "end") {
                            this.displayDetails[i].value = this.convertDateTime(data[keyData[x]]);
                        }
                        else{
                            this.displayDetails[i].value = data[keyData[x]];
                        }
                    }
                }

                if (this.displayDetails[i].attribute == "eventCar") {
                    if (data.status == "N/A") {
                        this.displayDetails[i].value = "Daily Car"
                    }
                    else{
                        this.displayDetails[i].value = "Project Car"
                    }
                }

            }
            this.detailData = data;
            this.dialogViewDataCarBook = true;
        }

    },
    mounted(){
        this.getCarBookingList();
    },
    computed: {
        headers () {
        
            return [
                { text: 'Date Applied', value: 'dateapplied' },
                { text: 'Car Type', value: 'carType' },
                { text: 'Event/Project', value: 'name' },
                { text: 'Start Date', value: 'start' },
                { text: 'End Date', value: 'end' },
                { text: 'Status', value: 'status' },
                { text: 'Detail', value: 'detail', sortable: false },
            ]

        },
        itemsWithIndex() {
            return this.leaveList.map(
                (leaveList, index) => ({
                ...leaveList,
                index: index + 1
                }))
        }
    }

}

</script>

<style lang="scss" scoped>
@import '~scss/main';


</style>
